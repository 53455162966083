<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <!-- 3단계 판단기준 목록 -->
    <c-table
      ref="table"
      title="LBL0001275"
      tableId="riskHazrd01"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable"
      @table-data-change="tableDataChange"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 추가 -->
          <c-btn v-if="editable" :showLoading="false" label="LBLADD" icon="add" @btnClicked="add" />
          <!-- 저장 -->
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save" 
            @beforeAction="saveRiskThirdStandard"
            @btnCallback="saveRiskThirdStandardCallback"/>
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'riskColor'">
          <div :style="`background-color:${props.row.riskColor};height:100%;`"></div>
        </template>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskThirdStandard',
  data() {
    return {
      searchParam: {
        plantCd: null,
      },
      grid: {
        columns: [],
        data: [],
      },
      riskAcceptItems: [],
      editable: true,
      isSave: false,
      listUrl: '',
      checkUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.riskThirdStandard.list.url
      this.checkUrl = selectConfig.ram.riskThirdStandard.check.url
      this.saveUrl = transactionConfig.ram.riskThirdStandard.save.url
      // code setting
      this.$comm.getComboItems('RAM_RISK_ACCEPT_CD').then(_result => {
        this.riskAcceptItems = _result;
        this.grid.columns = [
          {
            name: 'plantCd',
            field: 'plantCd',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            required: true,
            type: 'plant',
          },
          {
            name: 'ramRiskLevelCd',
            field: 'ramRiskLevelCd',
            // 위험수준
            label: 'LBL0001274',
            align: 'center',
            style: 'width:140px',
            sortable: true,
            required: true,
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            codeGroupCd: 'RAM_RISK_LEVEL_CD'
          },
          {
            name: 'ramRiskAcceptCd',
            field: 'ramRiskAcceptCd',
            // 허용가능 수준
            label: 'LBL0001273',
            align: 'left',
            style: 'width:100px',
            sortable: true,
            required: true,
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: this.riskAcceptItems
          },
          {
            name: 'riskColor',
            field: 'riskColor',
            // 위험색상
            label: 'LBL0001272',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'custom'
          },
          {
            name: 'judgmentCriteria',
            field: 'judgmentCriteria',
            // 판단기준
            label: 'LBL0001271',
            style: 'width:300px',
            align: 'left',
            sortable: true,
            type: 'textarea',
          },
        ]
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    tableDataChange(props, col) {
      if (col.name === 'ramRiskAcceptCd') {
        let accept = this.$_.find(this.riskAcceptItems, { code: props.row.ramRiskAcceptCd })
        if (accept) {
          this.$set(props.row, 'riskColor', accept.attrVal1)
        } else {
          this.$set(props.row, 'riskColor', 'white')
        }
      }
    },
    add() {
      if (!this.grid.data) {
        this.grid.data = [];
      }
      this.grid.data.splice(0, 0, {
        ramRiskThirdStandardId: uid(),  // 3단계 판단기준 일련번호
        plantCd: null,  // 사업장코드
        ramRiskLevelCd: null,  // 위험수준 코드
        judgmentCriteria: '',  // 판단기준
        ramRiskAcceptCd: null,  // 허용가능 수준
        riskColor: 'white',  // 위험색상
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    saveRiskThirdStandard() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        let plants = this.$_.uniq(this.$_.map(this.grid.data, 'plantCd'))
        if (plants && plants.length > 0) {
          let check = this.$_.isEmpty(this.$_.pickBy(this.$_.countBy(this.grid.data, item => {
            return [item.plantCd, item.ramRiskLevelCd]
          }), count => {
            return count > 1
          }))

          if (check) {
            this.$http.url = this.checkUrl;
            this.$http.type = 'POST';
            this.$http.param = this.$_.map(this.$_.filter(this.grid.data, item => {
              return item.editFlag === 'C' || item.editFlag === 'U'
            }), item => {
              return {
                plantCd: item.plantCd,
                ramRiskLevelCd: item.ramRiskLevelCd,
                ramRiskThirdStandardId: item.editFlag !== 'C' ? item.ramRiskThirdStandardId : null,
              }
            }),
            this.$http.request((_result) => {
              if (_result.data > 0) {
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message: 'MSG0000407', // 동일한 데이터가 존재합니다. \n[사업장, 위험수준]
                  type: 'warning', // success / info / warning / error
                });
                this.getList();
              } else {
                window.getApp.$emit('CONFIRM', {
                  title: 'LBLCONFIRM',
                  message: 'MSGSAVE', // 저장하시겠습니까?
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.isSave = !this.isSave
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                });
              }
            },);
          } else {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: 'MSG0000407', // 동일한 데이터가 존재합니다. \n[사업장, 위험수준]
              type: 'warning', // success / info / warning / error
            });
          }
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: 'MSG0000408', // 저장할 데이터가 없습니다.
            type: 'warning', // success / info / warning / error
          });
        }
      }
    },
    saveRiskThirdStandardCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
